import React from "react";
import AccordionItem from "../components/accordion-item";
import Layout from "../components/layout";
import Seo from "../components/seo"

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Privacy Policy"
        description="We take privacy matters seriously and want you to be familiar with how we collect, use, and disclose data. Read about our privacy policies today."
      />
      <section className="my-6">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Privacy Policy</h2>
              <p>Effective Date: December 10, 2019</p>
              <p>
                MacDuff Enterprises, Inc. dba 729 Solutions (the “Company”)
                takes privacy matters seriously and wants you to be familiar
                with how we collect, use, and disclose data. The Company
                provides this Privacy Policy to explain our practices regarding
                the collection, use, disclosure, and protection of personal
                information that apply to our website at
                https://729solutions.com/, including, without limitation, our
                mobile websites, mobile applications, social networking or other
                interactive media sites, other online or mobile services, and
                any other platforms that link to this Privacy Policy
                (collectively, the “Site”), as well as your choices regarding
                the collection and use of your information. This Privacy Policy
                does not apply to information we collect by means (including
                offline) or from other sources other than through the Site.
              </p>
              <p>
                As part of our commitment, we strive to protect the privacy of
                visitors to the Site. You can navigate the majority of the Site
                without giving us any personal information which identifies you.
                However, sometimes we need additional information about you in
                order to provide you with information or services you want to
                receive from us.
              </p>
              <p>
                In this digital world, privacy is important to the Company and
                should be important to you. The Company believes in transparency
                and we provide this notice so that you can understand our online
                information practices and the choices you can make about the way
                your information is collected and used.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-6">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="accordion-729">
                <AccordionItem
                  id="0"
                  checked
                  title="The Information We Collect and Process"
                  text={[
                    <p>
                      The Company collects and processes two basic types of
                      information:
                    </p>,
                    <p>
                      <i>Personally identifiable information:</i> This is
                      information that personally identifies one individual from
                      another or enables you to be individually contacted (for
                      example, names, e-mail addresses and other contact
                      information) (“Personally Identifiable Information”). This
                      information is voluntarily provided to us by Site visitors
                      and is used by us for the purpose of responding to user
                      submissions and fulfilling requests for further
                      information about our services. The Company only collects
                      Personally Identifiable Information that you voluntarily
                      provide, for example, when you use our “Contact Us”
                      feature. Under such circumstances you consent to our
                      use/processing of the information you provide consistent
                      with this policy.{" "}
                    </p>,
                    <p>
                      <i>Non-Personally Identifiable Information:</i> We also
                      collect and use information about your interactions with
                      the Site in a manner and format that does not identify you
                      as an individual (“Non-Personally Identifiable
                      Information”). This information gives us insights on how
                      Site visitors use our site. This data is anonymous and
                      does not contain any personally identifiable information.
                      We use this information to ensure that our website,
                      e-mails and marketing efforts continue to appeal to Site
                      visitors and clients. Generally, we do not combine or
                      associate Non-Personally Identifiable Information with
                      your Personally Identifiable Information. If we do combine
                      Non-Personally Identifiable Information with Personally
                      Identifiable Information, we treat the combined
                      information as Personally Identifiable Information. We may
                      collect, use, and disclose Non-Personally Identifiable
                      Information as set forth below.{" "}
                    </p>,
                  ]}
                />
                <AccordionItem
                  id="1"
                  title="The Way We Use Information"
                  text={[
                    <p>
                      We store the personal information you provide in a secure
                      database in order to provide you with the information,
                      products, and/or services you request and may be used to
                      provide you with additional information about our services
                      we believe may be of interest to you. The information is
                      stored for a time period and in a manner relevant to
                      responding to your request unless you request that it be
                      removed. The information you provide us will be shared
                      with the Company employees and service providers to the
                      extent necessary to administrate our systems and/or
                      accommodate your request.
                    </p>,
                    <p>
                      For example, if you provide your name, mailing address,
                      telephone and/or email address and request more
                      information about the Company’s services, this information
                      will be shared with appropriate Company personnel to
                      fulfill your request. This information will not be shared
                      with non-affiliated third parties without your consent.
                    </p>,
                    <p>
                      We use non-identifying and aggregate information about the
                      use of the Site to improve the navigation, content, and
                      design of the Site. This information may include, for
                      example, the most and least requested pages, analyzing
                      traffic regarding specific features, and the number of
                      users from the U.S. and other countries, among other
                      things.
                    </p>,
                    <p>
                      The Company will not sell or distribute personally
                      identifiable information collected from you outside of the
                      Company without your consent (subject to the limited
                      exceptions stated in this policy).
                    </p>,
                  ]}
                />
                <AccordionItem
                  id="2"
                  title="How Information May Be Shared or Disclosed"
                  text="The Company may preserve and has the right to disclose any information about you or your use of the Site without your prior permission if the Company has a good faith belief that such action is necessary to: (a) protect and defend the rights, property or safety of the Company or its clients or their respective partners, employees, affiliates, other users of the Site, or the public; or (b) respond to claims related to your use of the Site. We may also disclose information as required by law or subpoena to satisfy any applicable law, regulation, legal process or lawful governmental request (including to meet national security or law enforcement requirements) or in the event of a sale or potential sale, merger, acquisition, bankruptcy, or dissolution of the Company or one of its business units or similar transactions or proceedings."
                />
                <AccordionItem
                  id="3"
                  title="Google Analytics"
                  text={[
                    <p>
                      The Site uses Google Analytics, a web analytics service
                      provided by Google, Inc. (“Google”). Google Analytics uses
                      cookies to help the Site analyze how users use the Site.
                    </p>,
                    <p>
                      The Company uses Google Analytics features including:
                    </p>,
                    <ul className="text-primary">
                      <li>
                        <span className="text-dark">
                          Remarketing with Google Analytics
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          Google Display Network Impression Reporting
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          DoubleClick Platform integrations
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          Google Analytics Demographics and Interest Reporting
                        </span>
                      </li>
                    </ul>,
                    <p>
                      The Company uses all features of Google Analytics for
                      Display Advertisers. That includes obtaining specific
                      visitor cookie data, such as the source, medium and
                      keyword used to visit this website. Google Analytics does
                      not store any visitor specific data and we will not use
                      visitor specific data in any way related to Google
                      Analytics, Google AdWords, and Remarketing.
                    </p>,
                    <p>
                      The Company and other third-party vendors, including
                      Google, use first-party cookies such as the Google
                      Analytics cookie and third-party cookies such as the
                      DoubleClick cookie together to inform, optimize, and serve
                      ads based on visitor’s past visits to our website.
                    </p>,
                    <p>
                      The Company uses remarketing with Google AdWords (see
                      below) and analytics to display content specific
                      advertisements to visitors that have previously visited
                      our site when those visitors go to other websites that
                      have the Google Display Network implemented.
                    </p>,
                    <p>
                      The information generated by the cookie about your use of
                      the website including your IP address will be transmitted
                      to and stored by Google on servers in the United States.
                      Google will use this information for the purpose of
                      evaluating your use of the website, compiling reports on
                      website activity for website operators and providing other
                      services relating to website activity and internet usage.
                      Google may also transfer this information to third parties
                      where required to do so by law, or where such third
                      parties process the information on Google’s behalf. Google
                      will not associate your IP address with any other data
                      held by Google. You may refuse the use of cookies by
                      selecting the appropriate settings on your browser,
                      however if you do this you may not be able to use the full
                      functionality of the Site. By using this Site, you agree
                      to the processing of data about you by Google in the
                      manner and for the purposes explained in this paragraph.
                    </p>,
                    <p>
                      We use Google AdWords remarketing services to advertise on
                      third party websites (including Google) to previous
                      visitors to our Site. Remarketing allows us to tailor our
                      outreach to better suit your needs and only displays ads
                      that are relevant to you. AdWords remarketing may appear
                      in the form of an advertisement on the Google search
                      results page, or a site in the Google Display Network, or
                      display relevant ads tailored to your interests based on
                      what parts of the Company’s Site you have viewed or by
                      placing a cookie on your machine. This cookie does not in
                      any way identify you or give access to your computer. Any
                      data collected will be used in accordance with our Privacy
                      Policy.
                    </p>,
                    <h3>What is ReTargeting?</h3>,
                    <p>
                      The main goal of ReTargeting Ads is to re-engage site
                      visitors after they have left a site. Dynamic ReTargeting
                      Ads are generated based on the actual products or offers
                      viewed on the advertiser’s site. For example, if you’ve
                      visited a retailer’s website and viewed a specific
                      product, you might receive a banner ad containing that
                      product. Rather than showing the same ad to every visitor,
                      the site’s Dynamic Ad Application will select the most
                      relevant ad copy based on the user’s activity on the site.
                      The Site’s Personalized Retargeting Platform works when an
                      advertiser places our tag on his website. The tag embeds a
                      third party cookie on the browser of users visiting the
                      Site. Using Real Time Bidding (RTB) Ad Exchanges, it is
                      then possible to focus media buying efforts against those
                      visitors. It is important to note that the cookie itself
                      does not contain any personal identifying information. Our
                      Personalized ReTargeting ads intelligently match the right
                      offering to each visitor based on such visitor’s previous
                      activities on the Site. Each advertiser using the
                      Company’s service is responsible to limit the number of ad
                      impressions shown to each user in order to ensure that
                      viewers are not overly exposed to specific ads.
                    </p>,
                    <h3>How can I remove myself from being ReTargeted?</h3>,
                    <p>
                      The Site makes removing our tracking cookie easy for any
                      user who wishes to do so. Users that don’t want to see the
                      Company’s Personalized Ads should simply email us at{" "}
                      <a href="mailto:privacy@729solutions.com">
                        privacy@729solutions.com
                      </a>
                      .
                    </p>,
                  ]}
                />
                <AccordionItem
                  id="4"
                  title="Data Security"
                  text="The Company understands that the safety of your personal information is extremely important to you. Accordingly, the Company uses reasonable technological, physical and other measures to keep your information protected from loss, misuse and unauthorized access, disclosure, alteration and destruction, taking into due account the risks involved in the processing and the nature of the personal information. Due to the nature of the Internet and related technology, we cannot guarantee the security of any information, including your Personally Identifiable Information, and the Company expressly disclaims any such obligation."
                />
                <AccordionItem
                  id="5"
                  title="Retention Period"
                  text="We will retain information for the period necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law."
                />
                <AccordionItem
                  id="6"
                  title="Children's Privacy"
                  text="The Company understands the need to protect the privacy of children. The Site is directed to adults who are at least 18 years old and is not intended for use by individuals under 18 years of age. When a user discloses personal information on this Site, the user is representing that he or she is an adult. The Company does not knowingly collect or maintain personal information from children under the age of 18 at our Site. If we obtain knowledge that a child under the age of eighteen has provided us with personally identifiable information, we will promptly delete such information. If you are a parent or guardian and discover that your child has provided any such Personally Identifiable Information to us, then you may contact us at the email or mailing address noted at the end of this Privacy Policy below and request that we delete that information from our systems."
                />
                <AccordionItem
                  id="7"
                  title="How You Can Access or Correct Your Information"
                  text={[
                    <p>
                      You may access, update or remove the information you
                      provided to us by contacting us – see “How to Contact Us”
                      below. Similarly, Site users who wish to subscribe or
                      unsubscribe to our marketing and informational
                      communications or limit our use of any Personally
                      Identifiable Information in any way can do so by using the
                      subscribe/unsubscribe options contained in our emails or
                      by sending an email to{" "}
                      <a href="mailto:privacy@729solutions.com">
                        privacy@729solutions.com
                      </a>
                      . Upon receiving your email with the request to update or
                      remove your information, our privacy team will: 1) log the
                      receipt of your request, including the date and time
                      received; 2) take reasonable steps (including seeking
                      additional information or documentation) to verify your
                      identity, 3) evaluate the request (including, to the
                      extent necessary, contacting the appropriate data holder);
                      4) once the evaluation is completed, take action
                      consistent with the evaluation; and 5) respond to your
                      request accordingly. The information you provide us may be
                      archived or stored periodically by us according to backup
                      processes conducted in the ordinary course of business.
                      Information stored as part of this backup process will be
                      deleted in due course on a regular schedule. Some
                      information may be retained for longer periods as required
                      by law, contract or auditing requirements.
                    </p>,
                  ]}
                />
                <AccordionItem
                  id="8"
                  title="Links to Other Websites"
                  text="Our Site contains links to other websites. The Company is not responsible for any websites that it does not own or operate. You should carefully review the privacy policies and practices of other websites that you link to from the Site, as we cannot control or be responsible for their privacy practices."
                />
                <AccordionItem
                  id="9"
                  title="International Users"
                  text="Information provided through this Site may be uploaded to a cloud and transferred to or accessed globally. By providing information to this Site, you consent to the transfer, processing and/or use of the information globally throughout the Company. Wherever the information is transferred or accessed within the Company it will be processed and used in accordance with this privacy policy."
                />
                <AccordionItem
                  id="10"
                  title="Your Rights if You Are a Resident of the European Union or United Kingdom"
                  text={[
                    <p>
                      Subject to applicable data protection laws, customers and
                      users have the following rights with respect to the
                      Company’s handling of Personally Identifiable Information
                      for which the Company is the data controller:
                    </p>,
                    <ul className="text-primary">
                      <li>
                        <span className="text-dark">
                          <i>Access.</i> The right to request access to
                          Personally Identifiable Information held by the
                          Company.
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          <i>Objection.</i> The right to object to certain
                          processing of Personally Identifiable Information
                          (unless the Company has overriding compelling grounds
                          to continue processing), including the right to
                          opt-out of receiving direct marketing. We will,
                          however, continue to use Personally Identifiable
                          Information for the limited purpose of communicating
                          important notices relating to changes to the Service
                          or policies, and other reasons permitted by law.
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          <i>Rectification.</i> The right to request correction
                          of Personally Identifiable Information that is
                          incomplete, incorrect, unnecessary or outdated.
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          <i>Right to be Forgotten.</i> The right to request
                          erasure of all Personally Identifiable Information
                          that is incomplete, incorrect, unnecessary or outdated
                          within a reasonable period of time. The Company will
                          do everything possible to erase Personally
                          Identifiable Information if a user or customer so
                          requests. However, the Company will not be able to
                          erase all Personally Identifiable Information if it is
                          technically impossible due to limitations of existing
                          technology or for legal reasons, such as Fauna is
                          mandated by applicable law to retain Personally
                          Identifiable Information.
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          <i>Restriction of Processing.</i> The right to request
                          restriction of processing Personally Identifiable
                          Information for certain reasons, such as the
                          inaccuracy of Personally Identifiable Information.
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          <i>Data Portability.</i> If requested, the Company
                          will provide Personally Identifiable Information in a
                          structured, secure, commonly used and machine-readable
                          format.
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          <i>Right to Withdraw Consent.</i> If Personally
                          Identifiable Information is processed solely based on
                          consent, and not based on any other legal basis,
                          customers can withdraw consent at any time.
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          <i>Right to Withdraw Consent.</i> If Personally
                          Identifiable Information is processed solely based on
                          consent, and not based on any other legal basis,
                          customers can withdraw consent at any time.
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          <i>Data Protection Contact.</i> The right to contact
                          the relevant data protection regulator regarding the
                          Company’s handling of Personally Identifiable
                          Information.
                        </span>
                      </li>
                    </ul>,
                    <p>
                      To exercise any of the above listed rights, email the
                      Company at privacy@729solutions.com, or mail 729
                      Solutions, 388 MARKET STREET, SUITE 1300, SAN FRANCISCO,
                      CA 94104, Attn: Privacy. The Company will process requests
                      in accordance with applicable law, with reasonable efforts
                      and within a reasonable period of time.
                    </p>,
                  ]}
                />
                <AccordionItem
                  id="11"
                  title="Notification of Changes"
                  text="As we provide more services and/or functionality on our Site and as privacy laws and regulations evolve, it may be necessary to revise or update our Privacy Policy. We will put an “Updated” note on the link to the Privacy Policy on the Site’s home page so that you will be able to check regarding changes which may be of particular relevance to you."
                />
                <AccordionItem
                  id="12"
                  title="Do Not Track"
                  text="At this time, our Site does not support “Do Not Track” preferences that may be available in your browser for letting websites know that you do not want them collecting certain kinds of information. If you turn on the “Do Not Track” setting on your browser, our Site is not currently capable of following whatever Do Not Track preferences you set. For more information about Do Not Track, visit www.donottrack.us."
                />
                <AccordionItem
                  id="13"
                  title="California Privacy Rights"
                  text={[
                    <p>
                      Under California’s “Shine the Light” law (California Civil
                      Code Section 1798.83), California residents who provide
                      Personally Identifiable Information in obtaining products
                      and services for personal, family or household use are
                      entitled to request and obtain from the Company, once per
                      calendar year, information about Personally Identifiable
                      Information the Company has shared, if any, with other
                      businesses for their own direct marketing uses. The
                      Company does not currently share your Personally
                      Identifiable Information with other businesses for their
                      own direct marketing uses. However, if applicable, this
                      information would include the categories of Personally
                      Identifiable Information and the names and addresses of
                      those businesses with which the Company shared Personally
                      Identifiable Information for the immediately prior
                      calendar year (e.g., requests made in 2018 will receive
                      information regarding 2017 sharing activities). To obtain
                      this information, please send an email to
                      privacy@729solutions.com with “Request for California
                      Privacy Information” in the subject line, or via mail to:{" "}
                      <a href="mailto:privacy@729solutions.com">
                        privacy@729solutions.com
                      </a>{" "}
                      729 Solutions, 388 MARKET STREET, SUITE 1300, SAN
                      FRANCISCO, CA 94104, Attn: Privacy.
                    </p>,
                  ]}
                />
                <AccordionItem
                  id="14"
                  title="How to Contact Us"
                  text={[
                    <p>
                      If you ever have any questions about this Privacy Policy,
                      please contact us at{" "}
                      <a href="mailto:privacy@729solutions.com">
                        privacy@729solutions.com
                      </a>{" "}
                      or via mail at: 729 Solutions, 388 MARKET STREET, SUITE
                      1300, SAN FRANCISCO, CA 94104, Attn: Privacy.
                    </p>,
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Index;
